import { FormEventHandler, useEffect, useState } from 'react';
import { appInsights } from '../appInsights';
import { PasswordInput } from '../components/PasswordInput';
import { ReturnButton } from '../components/ReturnButton';
import { RegistrationState } from '../types';
import { getApiBaseUrl, getErrorMessage, getReturnUrl, parseUrl } from '../utils';
import { LoadingPage } from './LoadingPage';
import {
  NO_ACTIVE_TENANTS_ERROR_CODE,
  NoActiveTenantsErrorMessage,
} from './NoActiveTenantsErrorMessage';
import { UnknownClientPage } from './UnknownClientPage';

interface IProps {
  type: 'invitation' | 'signup';
}

export function RegistrationPage(props: IProps) {
  const { type } = props;

  const [tenantId, guid, accessToken] = parseUrl(window.location.hash);

  const [registrationState, setRegistrationState] = useState<RegistrationState>();
  const [loadingError, setLoadingError] = useState('');
  const [alreadyAccepted, setAlreadyAccepted] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [validationError, setValidationError] = useState('');
  const [serverError, setServerError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!registrationState && tenantId && guid && accessToken) {
      const encodedAccessToken = encodeURIComponent(accessToken);
      const url = `${getApiBaseUrl()}/${type}s/${tenantId}_${guid}/Status?accessToken=${encodedAccessToken}`;

      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      }).then((response) => {
        if (response.ok) {
          response.json().then((state: RegistrationState) => {
            if (state.ErrorCode) {
              switch (state.ErrorCode) {
                case 'InvitationAlreadyAccepted':
                case 'SignUpAlreadyVerified': {
                  setAlreadyAccepted(true);
                  break;
                }
                case 'AccessTokenInvalid': {
                  setLoadingError('This link appears to be invalid. Please check and try again?');
                  break;
                }
                case 'TenantNotFound': {
                  setLoadingError(NO_ACTIVE_TENANTS_ERROR_CODE);
                  break;
                }
                default: {
                  setLoadingError('An error occurred. Please refresh to try again.');
                  appInsights.trackException({
                    exception: new Error(
                      `Error: ${url} responded with ErrorCode ${state.ErrorCode}.`
                    ),
                  });
                }
              }
            } else {
              setRegistrationState(state);
            }
          });
        }
      });
    }
  }, [registrationState, tenantId, guid, accessToken, type]);

  if (!tenantId) {
    return <UnknownClientPage />;
  }

  if (loadingError) {
    return (
      <section className="dialog__wrapper">
        <div className="dialog">
          <div className="dialog__content dialog__content--no-controls">
            {loadingError === NO_ACTIVE_TENANTS_ERROR_CODE ? (
              <>
                <h2>Account deactivated</h2>
                <NoActiveTenantsErrorMessage />
              </>
            ) : (
              <>
                <h2>Error</h2>
                <p>{loadingError}</p>
              </>
            )}
          </div>
        </div>
      </section>
    );
  }

  if (alreadyAccepted) {
    return (
      <section className="dialog__wrapper">
        <div className="dialog">
          <div className="dialog__content">
            <h2>Error</h2>
            <p>It looks like you've already used this link.</p>
            <p>Click below to sign in</p>
          </div>
          <div className="dialog__controls">
            <ReturnButton>Sign In</ReturnButton>
          </div>
        </div>
      </section>
    );
  }

  if (serverError) {
    return (
      <section className="dialog__wrapper">
        <div className="dialog">
          <div className="dialog__content">
            <h2>Error</h2>
            <p>Sorry, something has gone wrong.</p>
            <p>
              Please try again later. If the problem persists, please contact support@tillr.io and
              we will try to help.
            </p>
          </div>
          <div className="dialog__controls">
            <ReturnButton>continue</ReturnButton>
          </div>
        </div>
      </section>
    );
  }

  const handleSubmit: FormEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (registrationState?.IsRegistrationRequired) {
      if (!firstName || !lastName || !password || password !== confirmPassword) {
        setValidationError(
          'Error - Please ensure you complete all fields, and that your password is valid and is confirmed.'
        );
        return;
      }
    }

    setSubmitting(true);

    const urlAction = type === 'signup' ? 'Verify' : 'Accept';
    const url = `${getApiBaseUrl()}/${type}s/${tenantId}_${guid}/${urlAction}`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        AccessToken: accessToken,
        NewUserDetails: registrationState?.IsRegistrationRequired
          ? {
              FirstName: firstName,
              LastName: lastName,
              Password: password,
            }
          : undefined,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(({ TenantSubdomain, TenantIsTillr3 }) => {
            const returnUrl = getReturnUrl(TenantSubdomain, TenantIsTillr3, accessToken);
            window.open(returnUrl, `_self`);
          });
        } else {
          getErrorMessage(response).then(({ errorMessage, showUser }) => {
            if (
              showUser &&
              (errorMessage === 'Invitation already accepted' ||
                errorMessage === 'Sign up already verified')
            ) {
              setAlreadyAccepted(true);
            } else {
              appInsights.trackException({ exception: new Error(errorMessage) });
              setServerError(true);
            }
          });
        }
      })
      .finally(() => setSubmitting(false));
  };

  if (!registrationState) {
    return <LoadingPage />;
  }

  if (!registrationState.IsRegistrationRequired) {
    return (
      <section id="registration-not-required" className="dialog__wrapper">
        <div className="dialog">
          <div className="dialog__content">
            <h2>Welcome</h2>
            <p>Thank you for verifying your email address.</p>
            <p>If you're happy and want to join {registrationState.TenantName} then click below.</p>
          </div>
          <div className="dialog__controls">
            <button type="button" id="action" onClick={handleSubmit}>
              Enter
            </button>
          </div>
        </div>
      </section>
    );
  }

  return (
    <form noValidate id="reg-form" className={submitting ? 'pending' : ''}>
      <section>
        <div className="progress"></div>
        <h1 id="title">Tillr Accounts</h1>
        <div className="heading">
          <h2>Welcome</h2>
          <p>
            Confirm your name and password to join
            {registrationState.TenantName && (
              <>
                {' '}
                <strong>{registrationState.TenantName}</strong>
              </>
            )}
            .
          </p>
        </div>

        {validationError && (
          <div id="reg-error" className="message error">
            <p>{validationError}</p>
          </div>
        )}

        <fieldset>
          <div className="form-group">
            <label htmlFor="reg-first-name">First name</label>
            <input
              type="text"
              id="reg-first-name"
              name="reg-first-name"
              required
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="reg-last-name">Last name</label>
            <input
              type="text"
              id="reg-last-name"
              name="reg-last-name"
              required
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="reg-password">Password</label>
            <PasswordInput id="reg-password" required onChange={setPassword} />
          </div>
          <div className="form-group">
            <label htmlFor="reg-confirm-password">Confirm password</label>
            <PasswordInput id="reg-confirm-password" required onChange={setConfirmPassword} />
          </div>
        </fieldset>
        <div className="form-group">
          <button id="reg-action" type="submit" onClick={handleSubmit} disabled={submitting}>
            Submit
          </button>
        </div>
      </section>
    </form>
  );
}

export const NO_ACTIVE_TENANTS_ERROR_CODE = 'ERROR_NO_ACTIVE_TENANTS';

export function NoActiveTenantsErrorMessage() {
  return (
    <p>
      Your organisation is not currently active on the Tillr platform. If you think this is an
      error, or to reactivate, please email{' '}
      <a href="mailto:support@tillr.io&subject=Deactivated organisation">support@tillr.io</a>.
    </p>
  );
}
